DeviceManager.Internal.CustomerPole = {

    enabled: function(options) {
        return false;
    },

    getCapabilities: async function() {
        let capabilities = {
            serial:     false,
        };

        return capabilities;
    },

    initialize: async function(args) {
    },

    test: async function(settings) {
        await DeviceManager.Internal.CustomerPole.disconnect();

        await DeviceManager.Internal.CustomerPole.configure(settings);

        await DeviceManager.Internal.CustomerPole.connect();

        await DeviceManager.Internal.CustomerPole.show([ "12345678901234567890", "€           Salonhub" ]);
    },
}