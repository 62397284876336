(() => {

    let interface = null;
    let configuration = null;
    
    DeviceManager.Internal.CustomerPole = Object.assign(DeviceManager.Internal.CustomerPole, {

        enabled: function(options) {
            return options.method == 'serial' ||
                   options.printer == '';
        },

        initialize: async function(args) {
            configuration = args;

            await DeviceManager.Internal.CustomerPole.disconnect();

            if (configuration.connection === 'serial') {
                interface = DeviceManager.Internal.CustomerPole.Serial;
            }

            if (interface) {
                await interface.initialize(configuration);

                DeviceManager.Internal.CustomerPole.reconnect();
            }
        },

        configure: async function(args) {
            configuration = args;

            if (configuration.connection === 'serial') {
                interface = DeviceManager.Internal.CustomerPole.Serial;
            }

            if (interface) {
                await interface.initialize(configuration);
            }
        },

        start: async function() {
            await DeviceManager.Internal.CustomerPole.disconnect();
            await DeviceManager.Internal.CustomerPole.connect();
        },

        stop: async function() {
            await DeviceManager.Internal.CustomerPole.disconnect();
        },
        

        reconnect: async function() {
            let device = await Runtime.Storage.read({ name: 'customerPole' });

            if (device) {
                if (configuration.connection === device.method) {
                    await interface.reconnect(configuration, device.display);
                }
            }
        },

        connect: async function() {
            let device = await interface.connect(configuration);

            if (device) {
                Runtime.Storage.write('customerPole', { method: configuration.connection, display: device });
            }
        },

        disconnect: async function() {
            if (interface && interface.connected) {
                await interface.disconnect();
            }
        },

        show: async function(data) {
            if (DeviceManager.Internal.CustomerPole.connected) {
                interface.show(data);
            }
        },

        getCapabilities: async function() {
            let capabilities = {
                serial:     'serial' in navigator,
            };

            return capabilities;
        }
    });

    Object.defineProperty(DeviceManager.Internal.CustomerPole, 'connected', {
        get() { 
            return interface?.connected; 
        }
    });

    Object.defineProperty(DeviceManager.Internal.CustomerPole, 'name', {
        get() { 
            return interface?.name; 
        }
    });

})();



/* Serial */

(() => {

    let WebSerialCustomerDisplay;

    let display;
    let connected = false;

    DeviceManager.Internal.CustomerPole.Serial = {

        initialize: async function() {
            WebSerialCustomerDisplay = await requireAsync([ 'core/lib/point-of-sale/webserial-customer-display.umd' ])
        },

        setup: function(configuration) {
            return new Promise(resolve => {
                if (display && display.disconnect) {
                    display.disconnect();
                }

                display = new WebSerialCustomerDisplay({
                    language:  configuration.language
                });

                display.addEventListener('connected', e => {
                    connected = true;
                    resolve(e);
                });

                display.addEventListener('disconnected', e => {
                    connected = false;
                });
            })
        },

        connect: async function (configuration) {
            let connected = DeviceManager.Internal.CustomerPole.Serial.setup(configuration);
            
            await display.connect();

            let result = await connected;

            this.show([ configuration.defaultOne, configuration.defaultTwo ]);

            return result;
        },

        reconnect: async function(configuration, device) {
            let connected = DeviceManager.Internal.CustomerPole.Serial.setup(configuration);

            await display.reconnect(device);

            let result = await connected;

            this.show([ configuration.defaultOne, configuration.defaultTwo ]);

            return result;
        },
        
        disconnect: async function () {
            if (display) {
                return new Promise(resolve => {
                    display.addEventListener('disconnected', resolve, { once: true });
                    display.disconnect();
                });
            }
        },

        show: function(data) {
            if (display) {
                display.line(data);
            }
        },

        get connected() {
            return connected;
        },

        get name() {
            return 'Naamloos apparaat';
        },

        get method() {
            return 'serial';
        }
    }

})();