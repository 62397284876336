
DeviceManager = Class.create({
	initialize: function(options) {
        this.options = Object.assign({
            application:                '',
            location:                   '',
            settings:                   {},

            onUpgradeSettings:           () => {},
            onCheckLogos:                () => {},
        }, options || {});


        /* Set up our manager for internal devices */

        this.internal = new DeviceManager.Internal({
            application:	this.options.application,
            settings:		this.options.settings
        });


        /* Setup our manager for external devices connected using SalonhubPOS */

        this.external = null;

        if (this.options.settings.method == 'external') {
			this.external = new DeviceManager.External({
				application:	this.options.application,
				settings:		this.options.settings,

				onInitialized: (manager) => {

                    /* Upgrade to realtime connection */ 

					let features = manager.capabilities.features || [];
					if (this.options.settings.guid == '' && features.includes('realtimeConnection')) {
						this.options.settings.guid = manager.capabilities.guid;
                        this.options.onUpgradeSettings(this.options.settings);
					}

                    /* Callback for external connection */
                    setTimeout(() => this.options.onCheckLogos(), 4000);
				}
			});
		}
	},

    update: function(settings) {
        this.options.settings = settings;
        
        /* Update internal manager */

        if (this.internal) {
            this.internal.update(settings);
        }


        /* Destroy existing and setup new manager for external devices connected using SalonhubPOS */

        if (this.external) {
            this.external.destroy();
            this.external = null;
        }

        if (this.options.settings.method == 'external') {
            this.external = new DeviceManager.External({ 
                application:    this.options.application, 
                settings:       this.options.settings,

                onInitialized: (manager) => {

                    /* Update logo */

                    setTimeout(() => {
                        if (this.options.settings.receiptPrinter.logo.enabled == -1) { 
                            this.options.onCheckLogos();
                        }

                        if (this.options.settings.receiptPrinter.logo.enabled == -2) { 
                            if (this.options.settings.receiptPrinter.logo.image.url) {
                                this.external.uploadLogo('receipt', this.options.settings.receiptPrinter.logo.image.url + '?w=480&h=240&bg=white&fit=contain&dither=1&fm=png');
                                this.external.uploadLogo('receipt-small', this.options.settings.receiptPrinter.logo.image.url + '?w=384&h=192&bg=white&fit=contain&dither=1&fm=png');
                                this.external.uploadLogo('sheet', this.options.settings.receiptPrinter.logo.image.url + '?w=480&h=240&bg=white&fit=contain&fm=png');
                            }
                            else {
                                this.external.clearLogo('receipt');
                                this.external.clearLogo('receipt-small');
                                this.external.clearLogo('sheet');
                            }
                        }
                    }, 4000);
				}
            });
        }
    },

    supports: function(feature) {
        let result = false;

        if (this.external) {
            let value = this.external.supports(feature);

            if (typeof value === 'boolean') {
                result = value;
            }
        }

        if (this.internal) {
            let value = this.internal.supports(feature);

            if (typeof value === 'boolean') {
                result = value;
            }
        }

        return result;
    },
        
    getCapabilities: async function(forceUpgrade) {
        if (this.external && forceUpgrade) {
            await new Promise(resolve => this.external.retrieveCapabilities(resolve));
        }

        return {
            settings:		this.options.settings,
            connected:		this.external?.enabled,
            capabilities:	{
                internal:	await this.internal.getCapabilities(),
                external:	this.external?.capabilities || {}
            }
        };
    },


    
    /* Payment terminal */

    paymentTransaction: async function(receipt, callback) {

        /* Handle internal CCV Cloud Connection */

        if (DeviceManager.Internal.PaymentTerminal.CcvCloudConnect.enabled(this.options.settings.paymentTerminal)) {
            let result = await DeviceManager.Internal.PaymentTerminal.CcvCloudConnect.startTransaction({
                endpoint:   Runtime.Controller.current.api,
                location:   this.options.location,
                device:     receipt.device,
                client:     receipt.client,
                salon:      receipt.salon,
                payment:    receipt.payment,
                method:     receipt.method,
                amount:     receipt.amount || null,
                resume:     receipt.resume || false,
            });

            callback(result.status, result.data || null)
            return;
        }

        /* Handle external integrations like CCV 2-step */

        if (this.external) {
            let success = this.external.paymentTransaction(receipt, callback);

            if (success) {
                return;
            }
        } 
        
        /* No payment terminal connected */

        callback(_PAYMENT_POS_NOTCONNECTED);
    },

    /* Sheet printer */

    printSheet: async function(item, callback) {
        let printer = this.options.settings.sheetPrinter.printer;
        let location = this.options.settings.sheetPrinter.location;

        if (location == 'internal' || printer == '*') {
            this.internal.printSheet(item, callback);
        }
        else if (location == 'external' && this.external) {

            /* SalonhubPOS can not handle HTML, so we convert it to PDF */
            
            if (item.type == 'text/html') {
                let { convertPDF } = await requireAsync(['core/helpers/services']);
                item = await convertPDF(item);
            }
    
            this.external.printSheet(item.url, callback);
        }
    },

    /* Receipt printer */

    printReceipt: function(data, callback) {
        let settings = this.options.settings.receiptPrinter;    

        let fallbackForced = false;

        if ('fallback' in data && data.fallback) {
            fallbackForced = true;
        }

        if (!fallbackForced && settings.printer != '') {
            let success = false;

            if (settings.location == 'internal') {
                success = this.internal.printReceipt(data.receipt, callback);
            }
            else if (settings.location == 'external' && this.external) {
                success = this.external.printReceipt(data.receipt, callback);
            }

            if (success) {
                return;
            }
        }

        DeviceManager.Internal.ReceiptPrinter.Fallback.print(settings, data.receipt, callback);
    },

    printTest: function() {
        let settings = this.options.settings.receiptPrinter;

        if (settings.location == 'internal') {
            this.internal.printTest();
        }
        else if (settings.location == 'external' && this.external) {
            this.external.printTest();
        }
    },

    uploadLogo: function(type, logo, callback) {
        let location = this.options.settings.receiptPrinter.location;

        if (location == 'external' && this.external) {
            this.external.uploadLogo(type, logo, callback);
        }
    },

    clearLogo: function(type, callback) {
        let location = this.options.settings.receiptPrinter.location;

        if (location == 'external' && this.external) {
            this.external.clearLogo(type, callback);
        }
    },

    /* Cash drawer */

    kickCashDrawer: function() {
        let settings = this.options.settings.cashDrawer;
        let location = settings.location;

        if (settings.connection == 'receiptPrinter') {
            location = this.options.settings.receiptPrinter.location;
        }
        
        if (location == 'internal') {
            this.internal.kickCashDrawer();
        }
        else if (location == 'external' && this.external) { 
            this.external.kickCashDrawer();
        }
    },

    /* Customer display */

    displayLines: function(lineOne, lineTwo) {
        let settings = this.options.settings.customerPole;
        let location = settings.location;

        if (location == 'internal') {
            this.internal.displayLines(lineOne, lineTwo);
        }
        else if (location == 'external' && this.external) { 
            this.external.displayLines(lineOne, lineTwo);
        }
    },

    displayDefault: function() {
        let settings = this.options.settings.customerPole;
        let location = settings.location;

        if (location == 'internal') {
            this.internal.displayDefault();
        }
        else if (location == 'external' && this.external) { 
            this.external.displayDefault();
        }
    },

    /* External display */

    openExternalDisplay: function() {
        return this.internal.openExternalDisplay();
    },

    closeExternalDisplay: function() {
        return this.internal.closeExternalDisplay();
    },

    isExternalDisplayOpen: function() {
        return this.internal.isExternalDisplayOpen();
    }
});



/* Test a device */

DeviceManager.Test = function(application, type, devices) {

	/* Test internal devices */

	if (type == 'externalDisplay') {
        new DeviceManager.Internal.ExternalDisplayPreview({ 
			url: '../../generic/js/devicemanager/internal/external-display/render.html?method=demo&fullscreen=auto&configuration=' + encodeURIComponent(JSON.stringify(devices.externalDisplay)) 
		});

		return;
	}

    if (type == 'receiptPrinter') {
        if (devices.receiptPrinter.location == 'internal') {
            return DeviceManager.Internal.ReceiptPrinter.test(devices.receiptPrinter);
        }
    }

    if (type == 'cashDrawer') {

        /* If the connection is set to receiptPrinter, and the receipt printer is local, handle it locally */

        if (devices.cashDrawer.connection == 'receiptPrinter' &&
            devices.receiptPrinter.location == 'internal') 
        {
            return DeviceManager.Internal.ReceiptPrinter.kick(devices.receiptPrinter, devices.cashDrawer.drawer);
        }
    }

    if (type == 'customerPole') {
        if (devices.customerPole.location == 'internal') {
            return DeviceManager.Internal.CustomerPole.test(devices.customerPole);
        }
    }


	/* Test external devices */

	if (devices.method != 'external') {
		return;
	}

	new DeviceManager.External({
		application:		application,
		settings:			devices,

		keepAlive: 			false,
		displayDefault: 	false,

		onConnected: 		(manager) => {
			if (type == 'receiptPrinter') {
				manager.printTest();
				window.setTimeout(() => manager.destroy(), 4000);
			}

			if (type == 'cashDrawer') {
				manager.kickCashDrawer();
				window.setTimeout(() => manager.destroy(), 4000);
			}
		
			if (type == 'customerPole') {
				manager.displayLines("12345678901234567890", "€       Salonhub POS");

				window.setTimeout(() => {
					manager.displayDefault();
					window.setTimeout(() => manager.destroy(), 4000);
				}, 4000);
			}
		}
	});
};