DeviceManager.Internal = Class.create({

	initialize: async function(options) {
        this.options = Object.assign({
			application:				null,
			settings:					{}
		}, options || {});

        this.update(this.options.settings);
    },

    update: async function(settings) {
        this.settings = settings;

        if (DeviceManager.Internal.ReceiptPrinter) {
            DeviceManager.Internal.ReceiptPrinter.initialize(this.settings.receiptPrinter);
        }

        if (DeviceManager.Internal.CustomerPole) {
            DeviceManager.Internal.CustomerPole.initialize(this.settings.customerPole);
        }

        if (DeviceManager.Internal.ExternalDisplay) {
            DeviceManager.Internal.ExternalDisplay.initialize(this.settings.externalDisplay);
        }

        if (DeviceManager.Internal.BarcodeScanner) {
            DeviceManager.Internal.BarcodeScanner.initialize(this.settings.barcodeScanner);
        }
    },

    supports: function(feature) {
        let result = false;

        if (DeviceManager.Internal.ReceiptPrinter.enabled(this.options.settings.receiptPrinter)) {
            let value = DeviceManager.Internal.ReceiptPrinter.supports(feature);

            if (typeof value === 'boolean') {
                result = value;
            }
        }
        
        if (DeviceManager.Internal.PaymentTerminal.CcvCloudConnect.enabled(this.options.settings.paymentTerminal)) {
            let value = DeviceManager.Internal.PaymentTerminal.CcvCloudConnect.supports(feature);

            if (typeof value === 'boolean') {
                result = value;
            }
        }

        return result;
    },

    getCapabilities: async function() {
        let capabilities = {
            devices: {},
            presets: {
                printers: [],
                ports: []
            }
        };

        if (DeviceManager.Internal.PaymentTerminal) {
            capabilities.devices.paymentTerminal = await DeviceManager.Internal.PaymentTerminal.getCapabilities();
        }

        if (DeviceManager.Internal.ExternalDisplay) {
            capabilities.devices.externalDisplay = await DeviceManager.Internal.ExternalDisplay.getCapabilities();
        }

        if (DeviceManager.Internal.BarcodeScanner) {
            capabilities.devices.barcodeScanner = await DeviceManager.Internal.BarcodeScanner.getCapabilities();
        }

        if (DeviceManager.Internal.SheetPrinter) {
            capabilities.devices.sheetPrinter = await DeviceManager.Internal.SheetPrinter.getCapabilities();
            capabilities.presets.printers = capabilities.presets.printers.concat(await DeviceManager.Internal.SheetPrinter.getPrinters());
        }

        if (DeviceManager.Internal.ReceiptPrinter) {
            capabilities.devices.receiptPrinter = await DeviceManager.Internal.ReceiptPrinter.getCapabilities();
            capabilities.presets.printers = capabilities.presets.printers.concat(await DeviceManager.Internal.ReceiptPrinter.getPrinters());
        }

        if (DeviceManager.Internal.CustomerPole) {
            capabilities.devices.customerPole = await DeviceManager.Internal.CustomerPole.getCapabilities();
        }

        return capabilities;
    },



    /* API for devices */


    
    /* Sheet printer */

    printSheet: function(item, callback) {
        DeviceManager.Internal.SheetPrinter.print({
            printer:    this.settings.sheetPrinter.printer,
            item:       item,
            callback:   callback
        });
    },


    /* Receipt printer */

    printReceipt: async function(receipt, callback) {
        await DeviceManager.Internal.ReceiptPrinter.print(this.settings.receiptPrinter, receipt);

        if (callback) {
            callback();
        }
    },

    printTest: function() {
        DeviceManager.Internal.ReceiptPrinter.printTest(this.settings.receiptPrinter);
    },

    /* Cash drawer */

    kickCashDrawer: function() {
        let settings = this.settings.cashDrawer;

        if (settings.connection == 'receiptPrinter' &&
            this.settings.receiptPrinter.location == 'internal') 
        {
            DeviceManager.Internal.ReceiptPrinter.kick(this.settings.receiptPrinter, settings.drawer);
        }
    },

    /* Customer pole */

    displayLines: function(lineOne, lineTwo) {
        DeviceManager.Internal.CustomerPole.show([
            lineOne,
            lineTwo
        ]);
    },

    displayDefault: function() {
        let settings = this.settings.customerPole;

        DeviceManager.Internal.CustomerPole.show([
            settings.defaultOne,
            settings.defaultTwo
        ])
    },

    /* External display */

    openExternalDisplay: function() {
        if (!DeviceManager.Internal.ExternalDisplay) {
            return;
        }

        DeviceManager.Internal.ExternalDisplay.open(this.settings.externalDisplay);
    },

    closeExternalDisplay: function() {
        if (!DeviceManager.Internal.ExternalDisplay) {
            return;
        }

        DeviceManager.Internal.ExternalDisplay.close();
    },

    isExternalDisplayOpen: function() {
        if (!DeviceManager.Internal.ExternalDisplay) {
            return false;
        }

        if (!DeviceManager.Internal.ExternalDisplay.isOpen) {
            return false;
        }

        return DeviceManager.Internal.ExternalDisplay.isOpen();
    }
});
