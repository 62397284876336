CSS.insert(`

    .deviceContainer {
        background: #f2f2f2;
        border-radius: 4px;
        margin-bottom: 10px;
    }

    .deviceBlock {
        margin: 0;
        padding: 6px 6px 12px 6px;
    }
    .deviceBlock:not(:last-child) {
        border-bottom: 1px solid #fff;
    }
    .deviceBlock > div {
        display: flex;
        align-items: start;
    }
    .deviceBlock > div img {
        width: 32px;
        margin: 6px 2px 6px 6px;
    }
    .deviceBlock > div div {
        font-size: 0.85em;
        flex: 1;
        margin-left: 10px;
    }   
    .deviceBlock > div div.buttons {
        flex: 0;
        align-self: end;
    }
	
    .deviceBlock h3 {
        font-size: 1em;
        margin: 6px 0 -2px;

        display: flex;
        align-items: center;
    }

    .deviceBlock h3 i::before {
        font-size: 18px;
        margin-right: 4px;
    }

    .deviceBlock p {
        margin: 6px 0 0;
        font-size: 0.8em;
        display: flex;
        align-items: center;
    }
    .deviceBlock p i {
        margin-right: 4px;
    }
    .deviceBlock p i::before {
        font-size: 16px;
        color: #666;
    }


    .deviceBlock .widget.button {
        margin: 0 10px 4px 0;
    }
    .deviceBlock .widget.button button {
        border: none !important;
        width: 24px !important;
        min-width: 24px !important;
    }

    .deviceBlock .bullet {
        margin-left: 4px;
    }

    .deviceBlock .bullet[data-color=green] {
        color: var(--colors-green);
    }

    .deviceBlock .bullet[data-color=red] {
        color: var(--colors-red);
    }

    .deviceSettings {
        margin: 0;
    }
`);


Devices = {
    initialize: function(parent, controller) {
        Devices._controller = controller;

        Devices._button = document.createElement('button');
        Devices._button.id = 'devices';
        Devices._button.update('\uE986');
        Devices._button.addEventListener('click', () => { Devices.show() });
        parent.appendChild(Devices._button);
    },

    show: function() {                    
        if (document.body.classList.contains('overlay')) {
            return;
        }

        let win = new Window({
            className: 	'deviceWindow',
            pointer: 	{ target: Devices._button, side: 'bottom', size: 20, corner: 8, distance: 16 },
            width: 		240,
            padding:    12,
            height:		'auto',
            onClose:	() => delete Devices._button.dataset.state
        });

        let devices = Devices._controller.settings.devices;
        
        if (devices) {
            let container = new Element('div');
            container.classList.add('deviceContainer');
            win.appendChild(container);
            

            /* Receipt printer */

            if (devices.receiptPrinter?.location == 'internal') {
                let icon;
                let method;

                switch (devices.receiptPrinter.method) {
                    case 'driver':
                        icon = '&#xE933;';
                        method = 'Printer';
                        break;
                    case 'network':
                        icon = '&#xE962;';
                        method = 'Netwerk';
                        break;
                    case 'bluetooth':
                        icon = '&#xE961;';
                        method = 'Bluetooth';
                        break;
                    case 'usb':
                        icon = '&#xE960;';
                        method = 'USB';
                        break;
                    case 'serial':
                        icon = '&#xE959;';
                        method = 'Seriële poort';
                        break;
                }

                let connected = DeviceManager.Internal.ReceiptPrinter.connected;
                let name = DeviceManager.Internal.ReceiptPrinter.name;

                let receiptPrinter = new Widgets.Panel(container, {
                    className: 'deviceBlock',
                    style: 'none',
                    template: `
                        <div>
                            <h3>Bonnenprinter</h3>
                            <p>
                                <i data-icon="custom-character" data-icon-character="${icon}"></i>
                                ${connected ? name : method}
                            </p>
                        </div>
                        <div class='buttons'></div>
                    `
                });

                if (devices.receiptPrinter?.method == 'bluetooth' ||
                    devices.receiptPrinter?.method == 'usb' ||
                    devices.receiptPrinter?.method == 'serial'
                ) {
                    if (!connected) {
                        new Widgets.Button(receiptPrinter.querySelector('.buttons'), {
                            color:  'white',
                            size:   'small',
                            icon:   { character: '\u002B', color: 'blue' },
                            onClick: () => {
                                win.close();
                                DeviceManager.Internal.ReceiptPrinter.connect();
                            }
                        });
                    } else {
                        new Widgets.Button(receiptPrinter.querySelector('.buttons'), {
                            color:  'white',
                            size:   'small',
                            icon:   { character: '\u00D7', color: 'red' },
                            onClick: () => {
                                win.close();
                                DeviceManager.Internal.ReceiptPrinter.disconnect();
                            }
                        });
                    }
                }
            }


            /* Barcode scanner */

            if (devices.barcodeScanner?.connection == 'webcam') {

                let barcodeScanner = new Widgets.Panel(container, {
                    className: 'deviceBlock',
                    style: 'none',
                    template: `
                        <div>
                            <h3>Barcode scanner</h3>
                            <p>
                                <i data-icon="custom-character" data-icon-character="&#xE97f;"></i>
                                Webcam
                            </p>
                        </div>
                        <div class='buttons'></div>
                    `
                });

                if (!DeviceManager.Internal.BarcodeScanner.connected) {
                    new Widgets.Button(barcodeScanner.querySelector('.buttons'), {
                        color:  'white',
                        size:   'small',
                        icon:   { character: '\uE98c', color: 'green' },
                        onClick: () => {
                            win.close();
                            DeviceManager.Internal.BarcodeScanner.start();
                        }
                    });
                } else {
                    new Widgets.Button(barcodeScanner.querySelector('.buttons'), {
                        color:  'white',
                        size:   'small',
                        icon:   { character: '\uE98d', color: 'red' },
                        onClick: () => {
                            win.close();
                            DeviceManager.Internal.BarcodeScanner.stop();
                        }
                    });
                }
            }

            else {
                let icon = '&#xE97e;';
                let method = 'Toetsenbord emulatie';

                if (devices.barcodeScanner.connection == 'hid') {
                    icon = '&#xE98b;';
                    method = devices.barcodeScanner.device.productName;
                }

                new Widgets.Panel(container, {
                    className: 'deviceBlock',
                    style: 'none',
                    template: `
                        <div>
                            <h3>Barcode scanner</h3>
                            <p>
                                <i data-icon="custom-character" data-icon-character="${icon}"></i>
                                ${method}
                            </p>
                        </div>
                    `
                });
            }


            /* Customer Pole */

            if (devices.customerPole?.connection == 'serial') {

                let customerPole = new Widgets.Panel(container, {
                    className: 'deviceBlock',
                    style: 'none',
                    template: `
                        <div>
                            <h3>Klantendisplay</h3>
                            <p>
                                <i data-icon="custom-character" data-icon-character="&#xE959;"></i>
                                Seriële poort
                            </p>
                        </div>
                        <div class='buttons'></div>
                    `
                });

                if (!DeviceManager.Internal.CustomerPole.connected) {
                    new Widgets.Button(customerPole.querySelector('.buttons'), {
                        color:  'white',
                        size:   'small',
                        icon:   { character: '\uE98c', color: 'green' },
                        onClick: () => {
                            win.close();
                            DeviceManager.Internal.CustomerPole.start();
                        }
                    });
                } else {
                    new Widgets.Button(customerPole.querySelector('.buttons'), {
                        color:  'white',
                        size:   'small',
                        icon:   { character: '\uE98d', color: 'red' },
                        onClick: () => {
                            win.close();
                            DeviceManager.Internal.CustomerPole.stop();
                        }
                    });
                }
            }


            /* External screen */

            if (devices.externalDisplay?.method && devices.externalDisplay?.manual) {
                let icon = '&#xE944;';
                let method = devices.externalDisplay.display;

                if (devices.externalDisplay.method == 'cast') {
                    icon = '&#xE964;';
                    method = 'Cast naar scherm';
                }

                let externalDisplay = new Widgets.Panel(container, {
                    className: 'deviceBlock',
                    style: 'none',
                    template: `
                        <div>
                            <h3>Extern scherm</h3>
                            <p>
                                <i data-icon="custom-character" data-icon-character="${icon}"></i>
                                ${method}
                            </p>
                            
                        </div>
                        <div class='buttons'></div>
                    `
                });

                if (!Devices._controller.devices.isExternalDisplayOpen()) {
                    new Widgets.Button(externalDisplay.querySelector('.buttons'), {
                        color:  'white',
                        size:   'small',
                        icon:   { character: '\uE98c', color: 'green' },
                        onClick: () => {
                            win.close();
                            Devices._controller.devices.openExternalDisplay()
                        }
                    });
                } else {
                    new Widgets.Button(externalDisplay.querySelector('.buttons'), {
                        color:  'white',
                        size:   'small',
                        icon:   { character: '\uE98d', color: 'red' },
                        onClick: () => {
                            win.close();
                            Devices._controller.devices.closeExternalDisplay()
                        }
                    });
                }
            }
        }

        /* SalonhubPOS status */

        if (Devices._controller.devices.external) {
            let container = new Element('div');
            container.classList.add('deviceContainer');
            win.appendChild(container);

            let version = Devices._controller.devices.external.getVersion();

            new Widgets.Panel(container, {
                className: 'deviceBlock',
                style: 'none',
                template: `
                    <div>
                        <p>
                            <i data-icon="custom-character" data-icon-character="&#xE96d;"></i>
                            SalonhubPOS ${version || ''}
                            <span class='bullet' data-color='${Devices._controller.devices.external.enabled ? 'green' : 'red'}'>●</span>
                        </p>
                    </div>
                `
            });
        }

        new Widgets.Button(win, {
            className: 'deviceSettings',
            title:  'Apparaat instellingen...',
            icon:   'settings',
            style:	'none',
            onClick: () => {
                win.close();

                let actors = Devices._controller.getActors();
                let settings = actors.filter(i => i.stage == 'admin' && i.actor == 'settings').pop();

                if (settings) {
                    Devices._controller.main.loader.change({ ...settings, path: 'index/devices' });
                }
            }
        });
        
        win.show();

        Devices._button.dataset.state = 'active';
    }
}